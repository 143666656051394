.home {
	.block {
		@apply flex flex-row justify-center gap-4;

		.like,
		.best {
			@apply bg-white mb-3 p-3 rounded-xl;
			width: calc(50% - 1.25rem);

			.slider {
				.item {
					@apply flex p-4 px-8 gap-4 max-h-[250px];

					img {
						width: 200px;
						height: 200px;
					}

					.info {
						@apply flex flex-col justify-evenly;

						> h2 {
							@apply font-extrabold;
							word-break: break-all;
						}

						p {
							@apply text-lg font-bold;
						}

						.btn {
							button {
								@apply mx-1;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1150px) {
	.home {
		.block {
			@apply flex flex-col;

			.like,
			.best {
				@apply w-full;

				.slider {
					.item {
						@apply w-full;
					}
				}
			}
		}
	}
}

@media (max-width: 650px) {
	.home {
		.block {
			@apply flex flex-col;

			.like,
			.best {
				img {
					width: auto;
					height: 150px;
					margin: auto;
				}

				.slider {
					@apply h-[400px];
					.item {
						@apply flex-col;
					}
				}
			}
		}
	}
}
